import { CTA } from "@/components";
import { Link, NextImage } from "@jog/react-components";
import { asLink, asText, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
export const VideoBanner = ({ slice: { primary, items }, className }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const showVideo = isFilled.image(primary.image_desktop) && isFilled.richText(primary.embed_url);
    const showMobileVideo = isFilled.image(primary.image_mobile) && isFilled.richText(primary.embed_url_mobile);
    if (!showVideo && !showMobileVideo)
        return <></>;
    const embedUrl = asText(primary.embed_url);
    const embedUrlMobile = asText(primary.embed_url_mobile);
    return (<div className={classNames("relative h-[100vw] w-full overflow-hidden md:h-full", className)}>
            <NextImage className="hidden md:inline md:h-auto md:w-full" src={primary.image_desktop.url || ""} height={(_b = (_a = primary === null || primary === void 0 ? void 0 : primary.image_desktop) === null || _a === void 0 ? void 0 : _a.dimensions) === null || _b === void 0 ? void 0 : _b.height} width={(_d = (_c = primary === null || primary === void 0 ? void 0 : primary.image_desktop) === null || _c === void 0 ? void 0 : _c.dimensions) === null || _d === void 0 ? void 0 : _d.width} sizes="100vw" alt=""/>
            <NextImage className="inline h-auto w-full md:hidden" src={((_e = primary === null || primary === void 0 ? void 0 : primary.image_mobile) === null || _e === void 0 ? void 0 : _e.url) || ""} sizes="100vw" height={(_g = (_f = primary === null || primary === void 0 ? void 0 : primary.image_mobile) === null || _f === void 0 ? void 0 : _f.dimensions) === null || _g === void 0 ? void 0 : _g.height} width={(_j = (_h = primary === null || primary === void 0 ? void 0 : primary.image_mobile) === null || _h === void 0 ? void 0 : _h.dimensions) === null || _j === void 0 ? void 0 : _j.width} alt=""/>
            <iframe className="absolute top-1/2 left-1/2 hidden h-full w-[178%] -translate-x-1/2 -translate-y-1/2 border-0 md:block md:h-[115%] md:w-full" src={embedUrl || ""} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
            <iframe className="absolute top-1/2 left-1/2 block h-full w-[178%] -translate-x-1/2 -translate-y-1/2 border-0 md:hidden md:h-[115%] md:w-full" src={embedUrlMobile || embedUrl || ""} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
            {isFilled.link(primary.video_banner_url) && (<Link className="z-1 absolute top-0 left-0 h-full w-full cursor-pointer" to={asLink(primary === null || primary === void 0 ? void 0 : primary.video_banner_url)}/>)}
            <div className="absolute z-[2] flex w-[70vw] -translate-x-1/2 -translate-y-1/2 flex-row flex-wrap items-center justify-center gap-2 md:flex-nowrap" style={{
            top: `${primary.cta_top_position || 80}%`,
            left: `${primary.cta_left_position || 50}%`,
        }}>
                {items
            .filter((item) => isFilled.title(item.cta_label) && isFilled.link(item.cta_url))
            .map((item, index) => (<Link to={asLink(item.cta_url)} key={index} onClick={(e) => e.stopPropagation()}>
                            <CTA color={primary.cta_text_color || ""} bgColor={primary.cta_bg_color || ""} colorHover={primary.cta_hover_text_color || ""} bgColorHover={primary.cta_hover_bg_color || ""} borderWidth={primary.cta_border_width || 0} borderColor={primary.cta_border_color || ""} fontSizeMobile={primary.cta_font_size_mobile || ""}>
                                {asText(item.cta_label)}
                            </CTA>
                        </Link>))}
            </div>
        </div>);
};
